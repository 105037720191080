import { useCallback, useEffect, useMemo, useState } from "react";
import Page from "../components/layouts/Page";
import Papa from "papaparse";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import useSWR from "swr";
import cfg from "../config";
//import { DateTime } from "luxon";
import { Deal, DEAL_TYPES } from "../const/deal";
import { DealGuard } from "../const/deal-guard";
import { guardStatusToColor } from "../lib/guardStatusToColor";
import toast from "react-hot-toast";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Dealer } from "../types/dealer";
import { DealerSelect } from "../components/containers/dealer/DealerSelect";
import { DealersProvider } from "../components/providers/DealersProvider";

const tableHeaders = ['Date', 'Country', 'Company name', 'Contact person', 'Equipment type', 'Quantity', 'Status of the deal'];
const guardStatuses = ['Pending', 'Approved', 'Rejected', 'Expired'];

export type GuardStatus = 'pending' | 'approved' | 'rejected' | 'expired';
export type DealType = 'directSale' | 'tender';
export type ProtectionPeriod = 1 | 3 | 6 | 12;
const dealTypes = ['Direct Sale', 'Tender'];

// const formats = ["dd.MM.yyyy", "d.MM.yyyy", "dd.m.yyyy", "dd/MM/yy", "d/MM/yyyy", "dd/m/yyyy"];

const dateFormats = [
  "DD.MM.YYYY",
  "D.MM.YYYY",
  "DD.M.YYYY",
  "DD/MM/YY",
  "D/MM/YYYY",
  "DD/M/YYYY",
  "DD.MM.YY",
  "DD-MM-YY"
];

const convertData = (formats: string[], targetFormat = "DD/MM/YYYY") => (date: string): Date | undefined => {
  for (const format of formats) {
    const parsedDate = moment(date, format, true)
    if (parsedDate.isValid()) {
      //return parsedDate.toJSDate();
      //return parsedDate.format(targetFormat);
      return parsedDate.toDate();
    }
  }
  //console.error('Invalid date format', date, formats);
  return undefined;
};
const parseDate = convertData(dateFormats);
// test


/*
const parseDateWithFormats = (inputDate: string, formats: string[]) => {
  for (const format of formats) {
    const parsedDate = DateTime.fromFormat(inputDate, format);
    if (parsedDate.isValid) {
      return parsedDate.toJSDate();
    }
  }

  console.error('Invalid date format', inputDate, formats);
  return undefined;
};
*/

const createKey = (obj: { country: string, customerName: string, email: string }) => {
  return `${obj.country}|${obj.customerName}|${obj.email}`;
}

const isValid = (item: KeyCVSData) => item['Date'] && item['Country']//  && item['Customer name']

type CVSData = {
  Date?: string;
  Country?: string;
  'Customer name'?: string;
  Email?: string;
  Phone?: string;
  'Equipment and quantity': string;
  Comments: string;
  Approved?: string;
}

type KeyCVSData = CVSData & { key: string };
async function importDataFromCSV(file: File): Promise<KeyCVSData[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const csvData = event.target.result;
      Papa.parse<CVSData>(csvData, {
        header: true, // Если заголовки включены
        skipEmptyLines: true,
        complete: (results) => {
          console.log("Parsed CSV Data:", results.data);
          resolve(results.data.map((item, index) => ({ ...item, key: uuidv4() })));
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          reject(error);
        },
      });
    };
    reader.readAsText(file);
  });
}

async function fetchDeals(partnerId: string): Promise<Deal[]> {
  const res = await fetch(`${cfg.apiUrl}/api/v1/deals?partnerId=${partnerId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },    
  });
  return res.json();
}

async function importDeals(partnerId: string, newDeals: ImportDeal[], guardStatus: GuardStatus, protectionPeriod: ProtectionPeriod) {
  const res = await fetch(`${cfg.apiUrl}/api/v1/deals/import`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      guardStatus,
      protectionPeriod,
      deals: newDeals.map((deal) => ({
        partnerId,
        ...deal,
      })),
    })
  });
  const result = await res.json();
}

type ImportDeal = {
  key: string;
  customerName?: string;
  country?: string;
  email?: string;
  phone?: string;
  comments?: string;
  createdAt?: Date;
  updatedAt?: Date;
  dealType: DealType;
}

function itemToDeal(item: KeyCVSData): ImportDeal {
  const date = item['Date'];
  if(!date) {
    throw new Error('Date is required for' + JSON.stringify(item));
  }

  const parsedDate = parseDate(date);
  if (!parsedDate) {
    throw new Error('Invalid date format: ' + date);
  }

  return {
    key: item.key,
    customerName: item['Customer name'] || '-',
    country: item['Country'] || '-',
    email: item['Email'],
    phone: item['Phone'],
    comments: item['Equipment and quantity'] + item['Comments'],
    createdAt: parsedDate,
    updatedAt: parsedDate,
    dealType: 'directSale',
  };
}

const filterNotRejected = (item: any) => item['Approved'] !== 'Rejected';
function calculateNewDeals(deals: Deal[], importData: KeyCVSData[]): ImportDeal[] {
  const set = new Set(deals.map(createKey));
  console.log('set', set);

  const filteredData = importData.filter(filterNotRejected);
  const newDeals: ImportDeal[] = [];
  filteredData.forEach((item: any) => {
    if (
      !set.has(createKey({ country: item['Country'] || '-', customerName: item['Customer name'] || '-', email: item['Email'] }))
    ) {
      try {
        const newDeal = itemToDeal(item);
        newDeals.push(newDeal);
      } catch (error) {
        console.error(error);
      }
  
    }
  });
  return newDeals;
}

function ImportDealsPage() {
  const [file, setFile] = useState<File | null>(null);
  const [selectedDealer, setSelectedDealer] = useState<Dealer>();
  const [importData, setImportData] = useState<KeyCVSData[]>([]);
  const [guardStatus, setGuardStatus] = useState<GuardStatus>('pending');
  const [dealType, setDealType] = useState<DealType>('directSale');
  const [protectionPeriod, setProtectionPeriod] = useState<ProtectionPeriod>(12);

  const [deals, setDeals] = useState<Deal[]>([]);
  const [newDeals, setNewDeals] = useState<ImportDeal[]>([]);

  const handleFileChange = useCallback((event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid CSV file.");
    }
  }, []);

  const handleFileRead = useCallback(async () => {
    if (!file) {
      alert("Please select a CSV file first.");
      return;
    }

    try {
      const newData = await toast.promise(importDataFromCSV(file), {
        loading: 'Parsing file...',
        success: 'File parsed successfully',
        error: 'Error parsing file',
      });
      setImportData(newData);
    } catch (error) {
      console.error(error);
    }
  }, [file]);

  const handleSelectDealer = useCallback((_: any, value: any) => {
    setSelectedDealer(value);
  }, []);

  const handleLoadDeals = useCallback(async () => {
    if (!selectedDealer) {
      alert("Please select a dealer first.");
      return;
    }

    try {
      const newDeals = await toast.promise(fetchDeals(selectedDealer.id), {
        loading: 'Loading dealer deals...',
        success: 'Dealer deals loaded successfully',
        error: 'Error loading dealer deals',
      });
      setDeals(newDeals);
  
      const data = calculateNewDeals(newDeals, importData);
      setNewDeals(data);
    } catch (error) {
      console.error(error);
      // @ts-ignore
      toast.error(error.message);
    }

  }, [selectedDealer, deals, importData, dealType]);

  const handleChangeGuardStatus = useCallback((event: SelectChangeEvent<GuardStatus>) => {
    setGuardStatus(event.target.value as GuardStatus);
  }, []);

  const handleChangeDealType = useCallback((event: SelectChangeEvent<DealType>) => {
    setDealType(event.target.value as DealType);
  }, []);

  const handleChangeProtectionPeriod = useCallback((event: SelectChangeEvent<ProtectionPeriod>) => {
    setProtectionPeriod(event.target.value as ProtectionPeriod);
  }, []);

  const handleExport = useCallback(async () => {
    if (!selectedDealer) {
      alert("Please select a dealer first.");
      return;
    }

    try {
      await toast.promise(importDeals(selectedDealer.id, newDeals, guardStatus, protectionPeriod), {
        loading: 'Saving data...',
        success: 'Data saved successfully',
        error: 'Error saving data',
      });
    } catch (error) {
      console.error(error);
    }
  }, [selectedDealer, newDeals, guardStatus, protectionPeriod]);

  // new deals to map
  const newDealsKeys = useMemo(() => Object.fromEntries(newDeals.map((deal) => [deal.key, deal])), [newDeals]);

  return (
    <Page title='Import Deals'>
      <Stack p={2} direction="row" spacing={2}>
        <TextField
          label="File Name"
          variant="outlined"
          type="file"
          onChange={handleFileChange}
          inputProps={{ accept: ".csv" }}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
        <Button
          variant="outlined"
          disabled={!file}
          onClick={handleFileRead}
        >
          Parse File
        </Button>
        <Box display='flex' justifyContent='flex-end'>
          <DealerSelect
            id="dealer-filter"
            sx={{ minWidth: 300 }}
            onChange={handleSelectDealer}
            size="small"
          />
        </Box>
        <Button
          variant="outlined"
          disabled={!file || !selectedDealer}
          onClick={handleLoadDeals}
        >
          Load dealer data
        </Button>
      </Stack>
      <Stack p={2} direction="row" spacing={2}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="guard-status-select-label">Guard status</InputLabel>
          <Select
            labelId="guard-status-select-label"
            id="guard-status-select"
            value={guardStatus}
            label="Guard status"
            size="small"
            onChange={handleChangeGuardStatus}
          >
            <MenuItem value={'pending'}>Pending</MenuItem>
            <MenuItem value={'approved'}>Approved</MenuItem>
            <MenuItem value={'rejected'}>Rejected</MenuItem>
            <MenuItem value={'expired'}>Expired</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="deal-type-select-label">Deal type</InputLabel>
          <Select
            labelId="deal-type-select-label"
            id="deal-type-select"
            value={dealType}
            label="Deal type"
            size="small"
            onChange={handleChangeDealType}
          >
            <MenuItem value={'directSale'}>Direct Sale</MenuItem>
            <MenuItem value={'tender'}>Tender</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="protection-period-select-label">Protection period</InputLabel>
          <Select
            labelId="protection-period-select-label"
            id="protection-period-select"
            value={protectionPeriod}
            label="Protection period"
            size="small"
            onChange={handleChangeProtectionPeriod}
          >
            <MenuItem value={1}>1 month</MenuItem>
            <MenuItem value={3}>3 months</MenuItem>
            <MenuItem value={6}>6 months</MenuItem>
            <MenuItem value={12}>1 year</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={!selectedDealer || newDeals.length === 0}
          variant="outlined"
          onClick={handleExport}
        >
          Save data
        </Button>
      </Stack>
      <Typography variant="h6" align="center" mt={3} mb={2}>
        {`Selected ${newDeals.length} deals for import`}
      </Typography>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Import</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Customer name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Approved</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {importData.map((data: KeyCVSData) => isValid(data) ? (
                <TableRow
                  key={data.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Typography color={newDealsKeys[data.key] ? 'Green' : 'inherit'}>
                      {newDealsKeys[data.key] ? 'Yes' : 'Allready exists'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={(!data.Date || !parseDate(data.Date)) ? 'red' : 'inherit'}>
                      {data['Date']}
                    </Typography>
                  </TableCell>
                  <TableCell>{data['Country']}</TableCell>
                  <TableCell>{data['Customer name']}</TableCell>
                  <TableCell>{data['Email']}</TableCell>
                  <TableCell>{(data['Equipment and quantity'] || '') + ' ' + (data['Comments'] || '')}</TableCell>
                  <TableCell>{data['Approved']}</TableCell>
                </TableRow> 
              ): (
                <TableRow
                  key={data.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Typography color={'red'}>
                      Invalid
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={!data['Date'] ? 'red' : 'inherit'}>
                      {!data['Date'] ? 'Date is required' : data['Date']}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={!data['Country'] ? 'red' : 'inherit'}>
                      {!data['Country'] ? 'Country is required' : data['Country']}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={!data['Customer name'] ? 'red' : 'inherit'}>
                      {!data['Customer name'] ? 'Customer name is required' : data['Customer name']}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={!data['Email'] ? 'red' : 'inherit'}>
                      {!data['Email'] ? 'Email is required' : data['Email']}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={'red'}>
                      {JSON.stringify(data)}
                    </Typography>
                  </TableCell>
                </TableRow> 
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
}

function ImportDealsPageWithProviders() {
  return (
    <DealersProvider>
      <ImportDealsPage />
    </DealersProvider>
  );
} 


export default ImportDealsPageWithProviders;
