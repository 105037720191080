import cfg from '../config';
import { Deal, DealData } from '../const/deal';

const url = `${cfg.apiUrl}/api/v1/deals`;

type RegisterDTO = DealData & {
  protectionExpiryDate?: string;
}
export const register = async (data: RegisterDTO): Promise<Deal> => {
  const res = await fetch(`${cfg.apiUrl}/api/v1/me/deals/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json()
};

export const updateRequest = async (id: string, data: DealData): Promise<Deal> => {
  const res = await fetch(`${cfg.apiUrl}/api/v1/me/deals/${id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json()
};

export const removeRequest = async (id: string): Promise<Deal> => {
  const res = await fetch(`${cfg.apiUrl}/api/v1/me/deals/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json()
};

export const create = async (data: Deal) => {
  const res = await fetch(`${url}/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const update = async (data: Deal): Promise<Deal> => {
  const res = await fetch(`${url}/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const remove = async (id: string): Promise<Deal> => {
  const res = await fetch(`${url}/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();  
};