import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../components/layouts/Page';
import {
  Box,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography
} from '@mui/material';
import RegisterDealButton from '../components/request/RequestDealButton';
import EditReqestButton from '../components/request/EditReqestButton';
import { DealGuard } from '../const/deal-guard';
import { Deal, DEAL_TYPES } from '../const/deal';
import useSWR from 'swr';
import { guardStatusToColor2 } from '../lib/guardStatusToColor';
import SearchLine from '../components/dumps/SearchLine';
import { dateToString, filderDeal, guardStatusToTitle, guardToStatus } from '../lib/deal';
import CancelDealButton from '../components/request/CancelDealButton';

const tableHeaders = ['Date', 'Customer name', 'Country', 'Email', 'Phone', 'Deal type', 'Guard status', 'Edit', 'Actions'];

type DealItemProps = TableRowProps & {
  deal: Deal;
  dealGuard?: DealGuard;
  onEdited: (data: Deal) => void;
  onCanceled: (dealId: string) => void;
}

const DealItem: React.FC<DealItemProps> = ({ deal, dealGuard, onEdited, onCanceled, ...props }) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      {...props}
    >
      <TableCell>{dateToString(deal.createdAt)}</TableCell>
      <TableCell>{deal.customerName}</TableCell>
      <TableCell>{deal.country}</TableCell>
      <TableCell>{deal.email}</TableCell>
      <TableCell>{deal.phone}</TableCell>
      <TableCell>{DEAL_TYPES[deal.dealType]}</TableCell>
      <TableCell>
        <Typography variant='body2' sx={{ color: guardStatusToColor2(dealGuard?.status) }}>
          {guardToStatus(dealGuard)}
        </Typography>
      </TableCell>
      <TableCell>
        <EditReqestButton
          init={deal}
          initTenderDate={dealGuard?.protectionExpiryDate}
          disabled={dealGuard?.status !== 'pending'}
          onUpdated={onEdited}
        />
      </TableCell>
      <TableCell>
        <CancelDealButton
          dealId={deal.id}
          variant='outlined'
          color='secondary'
          disabled={dealGuard?.status !== 'pending'}
          onRemove={onCanceled}
        />
      </TableCell>
    </TableRow>
  )
}
const MemoDealItem = React.memo(DealItem);

function useDeals({ search, limit }: { search: string, limit: number }) {
  const { data: deals = [], isLoading, mutate } = useSWR<Deal[]>('/api/v1/me/deals');

  const filteredDeals = useMemo(() =>
    deals.filter((deal: Deal) => filderDeal(deal, search)),
    [deals, search]
  );

  return {
    isLoading,
    deals: filteredDeals.slice(0, limit),
    amount: filteredDeals.length,
    mutate,
  };
}

const MyDeals: React.FC = () => {
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);

  const { deals, isLoading, amount, mutate } = useDeals({ search, limit });

  const handleAddDeal = useCallback(async (newDeal: Deal) => {
    try {
      // @ts-ignore
      await mutate((d: Deal[]) => [...d, newDeal], false);
    } catch (error) {
      console.error(error);
    }
  }, [])

  const handleEditDeal = useCallback(async (data: Deal) => {
    try {
      // @ts-ignore
      await mutate((d: Deal[]) => d.map((d: Deal) => d.id === res.id ? data : d), false);
    } catch (error) {
      console.error(error);
    }
  }, [])

  const handleCancelDeal = useCallback(async (dataId: string) => {
    try {
      // @ts-ignore
      await mutate((d: Deal[]) => d.filter((d: Deal) => d.id !== dataId), false);
    } catch (error) {
      console.error(error);
    }
  }, [])

  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.body.offsetHeight - 300; // 300px

    if (scrollPosition >= threshold && limit < amount) {
      setLimit(prevLimit => Math.min(prevLimit + 20, amount));
    }
  }, [limit, amount]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = useCallback((filter: string) => {
    setSearch(filter);
    setLimit(20);
  }, [])

  return (
    <Page>
      <Stack direction="row" py={2} gap={2}>
        <Box sx={{ flexGrow: 1 }}>
          <SearchLine
            onChangeFilter={handleSearch}
            onSearch={handleSearch}
          />
        </Box>
        <RegisterDealButton onRegistered={handleAddDeal} sx={{ minWidth: '140' }} />
      </Stack>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {deals.map((deal) => (
                <MemoDealItem
                  key={deal.id}
                  deal={deal}
                  dealGuard={deal.guard}
                  onEdited={handleEditDeal}
                  onCanceled={handleCancelDeal}
                />
              ))}
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={8} align="center" sx={{ border: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
};

export default MyDeals;