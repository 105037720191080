import { Button, ButtonProps } from "@mui/material";
import { useCallback, useState } from "react";
import RequestDealDialog from "./RequestDealDialog";
import { Deal, DealData } from "../../const/deal";
import * as dealApi from '../../api/deal';
import toast from "react-hot-toast";

type Props = Omit<ButtonProps, 'onClick' | 'children' | 'onSubmit'> & {
  onRegistered?: (data: Deal) => void
}

function RequestDealButton({ onRegistered, ...props }: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() =>  setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(async (data: DealData, protectionExpiryDate?: string) => {
    try {
      const payload = { ...data, protectionExpiryDate };
      const newDeal = await toast.promise(dealApi.register(payload), {
        loading: 'Creating deal...',
        success: 'Deal created successfully',
        error: 'Failed to create deal'
      });

      onRegistered?.(newDeal);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }, [onRegistered]);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen} {...props}>
        New Protection
      </Button>
      {open && 
        <RequestDealDialog open onClose={handleClose} onSave={handleSubmit} />
      }
    </>
  );
}

export default RequestDealButton;
