import { Button, ButtonProps } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useCallback, useState } from "react";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import * as dealApi from '../../api/deal';
import toast from "react-hot-toast";

export type CancelDealButtonProps = Omit<ButtonProps, 'onClick' | 'children' | 'onSubmit'> & {
  dealId: string
  onRemove?: (dealId: string) => void
  confirmTitle?: string
  confirmContent?: string
}

function CancelDealButton({ dealId, onRemove, confirmTitle, confirmContent, ...props }: CancelDealButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleRemove = useCallback(async () => {
    try {
      await toast.promise(dealApi.removeRequest(dealId), {
        loading: 'Cancelling deal...',
        success: 'Deal cancelled',
        error: 'Failed to cancel deal'
      });
      setOpen(false);
      onRemove?.(dealId);
    } catch (error) {
      console.error(error)
    }
  }, [dealId, onRemove]);

  return (
    <>
      <Button
        aria-label="delete"
        onClick={handleOpen}
        startIcon={<Cancel />}
        {...props}
      >
        Cancel
      </Button>
      {open && 
        <ConfirmDialog
          open={open}
          title={confirmTitle || 'Cancel Request'}
          content={confirmContent || 'Are you sure you want to cancel this request?'}
          rejectButtonText={'Close'}
          confirmButtonText={'Cancel Request'}
          onReject={handleClose}
          onConfirm={handleRemove}
      />
      }
    </>
  );
}

export default CancelDealButton;
