import React, { useCallback } from 'react';
import Page from '../components/layouts/Page';
import { Box, Divider, Grid, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { guardStatusToColor } from '../lib/guardStatusToColor';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import SimilarDeals from '../components/containers/deal/SimilarDeals';
import { Deal, DEAL_TYPES } from '../const/deal';
import EditDealButton from '../components/containers/deal/EditDealButton';
import { DealersProvider } from '../components/providers/DealersProvider';
import { DealProvider, useDeal } from '../components/providers/DealProvider';
import ApproveDealButton from '../components/containers/deal/ApproveDealButton';
import RejectDealButton from '../components/containers/deal/RejectDealButton';
import { dateToString } from '../lib/deal';
import CustomerName from '../components/containers/deal/CustomerName';

function DealInfoSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={3.5}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={2.5}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={2.5}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={1.5}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' height={40} />
      </Grid>
    </Grid>
  )
}

function DealInfo() {
  const { deal } = useDeal();
  // Generate Sekelon for loading state
  if(!deal) {
    return <DealInfoSkeleton />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField label='Customer name' value={deal.customerName} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
      </Grid>
      <Grid item xs={4}>
        <TextField label='Country' value={deal.country} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
      </Grid>
      <Grid item xs={3.5}>
        <TextField label='Email' value={deal.email || '-'} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
      </Grid>
      <Grid item xs={2}>
        <TextField label='Phone' value={deal.phone || '-'} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          label='Created at'
          value={DateTime.fromISO(deal.createdAt as string || '').toLocaleString(DateTime.DATETIME_MED)}
          InputProps={{ readOnly: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          label='Lead coming date'
          value={deal.leadComingDate ? DateTime.fromISO(deal.leadComingDate as string).toLocaleString(DateTime.DATE_MED) : '-'}
          InputProps={{ readOnly: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          label='Deal type'
          value={DEAL_TYPES[deal.dealType as keyof typeof DEAL_TYPES]}
          InputProps={{ readOnly: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Comments'
          value={deal.comments}
          InputProps={{ readOnly: true, multiline: true }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
    </Grid>
  )
}

const DealGuardInfoSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant='text' height={40} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant='text' height={40} />
      </Grid>
    </Grid>
  )
}

const DealGuardInfo = () => {
  const { deal } = useDeal();
  const guard = deal?.guard;

  if(!guard) {
    return <DealGuardInfoSkeleton />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <TextField
          label='Protection expiry date'
          value={dateToString(guard.protectionExpiryDate)}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label='Guard status'
          value={guard.status.toUpperCase()}
          color={guardStatusToColor(guard.status)}
          focused
          InputProps={{ readOnly: true }}
          fullWidth
          size='small'
          sx={{ input: { cursor: 'default' } }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label='Updated at'
          value={DateTime.fromISO(guard.updatedAt || '')?.toLocaleString(DateTime.DATETIME_MED)}
          InputProps={{ readOnly: true }}
          fullWidth
          size='small'
          disabled
        />
      </Grid>
      {guard.status === 'rejected' &&
        <Grid item xs={12}>
          <TextField
            label='Rejection reason'
            value={guard.reason}
            InputProps={{ readOnly: true }}
            fullWidth
            size='small'
            disabled
          />
        </Grid>
      }
    </Grid>
  )
}

const DealGuardPage: React.FC = () => {
  const { deal, mutate } = useDeal();


  const handleUpdateDeal = useCallback(async () => {
    try {
      await mutate();
    } catch (error) {
      console.error(error);
    }
  }, [mutate]);


  // initTenderDate={deal.tenderDate}
  return (
    <Page>
      <Box my={4} gap={2} display='flex' flexDirection='column'>
        <Paper sx={{ pt: 1, px: 2, pb: 2 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <CustomerName />
            <EditDealButton
              disabled={!deal}
              init={deal!}
              onUpdated={handleUpdateDeal}
            />
          </Stack>
          <Divider sx={{ mt: 1, mb: 3 }}/>
          <DealInfo />
        </Paper>
        <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
          <Typography variant='h6'>Deal Guard</Typography>
          <DealGuardInfo />
          <Stack direction='row' spacing={2} >
            <ApproveDealButton />
            <RejectDealButton />
          </Stack>
        </Paper>
        <Typography variant='h5' align='center' my={2}>Similar Deals</Typography>
        <SimilarDeals />  
      </Box>
    </Page>
  );
};

const DealGuardPageWithProviders: React.FC = () => {
  const { id: dealId } = useParams<{ id: string }>();

  return (
    <DealersProvider>
      <DealProvider dealId={dealId as string}>
        <DealGuardPage />
      </DealProvider>
    </DealersProvider>
  )
}

export default DealGuardPageWithProviders;