import React, { createContext, useContext } from 'react';
import useSWR from 'swr';
import { Deal } from '../../const/deal';

interface DealContextType {
  dealId: string;
  deal: Deal | undefined;
  isLoading: boolean;
  mutate: () => void;
}

const DealContext = createContext<DealContextType | undefined>(undefined);

export const DealProvider: React.FC<{ dealId: string, children: React.ReactNode }> = ({ dealId, children }) => {
  const { data: deal, isLoading, mutate } = useSWR<Deal>(`/api/v1/deals/${dealId}`);

  return (
    <DealContext.Provider value={{ dealId, deal, isLoading, mutate }}>
      {children}
    </DealContext.Provider>
  );
};

export const useDeal = (): DealContextType => {
  const context = useContext(DealContext);
  if (!context) {
    throw new Error('useDealContext must be used within a DealProvider');
  }
  return context;
};
