import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import * as dealGuardsApi from '../../../api/deal-guards';
import DealGuardRejectDialog from '../../../components/dialogs/DealGuardRejectDialog';
import toast from 'react-hot-toast';
import { useDeal } from '../../providers/DealProvider';

export type RejectDealButtonProps = ButtonProps;

const RejectDealButton: React.FC<RejectDealButtonProps> = ({ disabled, ...props }) => {
  const { deal, isLoading, mutate } = useDeal();

  const [openReject, setOpenReject] = useState(false);

  const guard = deal?.guard;
  const guardStatus = guard?.status

  const handleRejectDealGuard = useCallback(async (reason: string) => {
    try {
      if (guard) {
        await toast.promise(dealGuardsApi.update({ ...guard, status: 'rejected', reason }), {
          loading: 'Rejecting...',
          success: 'Guard rejected successfully',
          error: 'Failed to reject guard'
        });  
        mutate();
        setOpenReject(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [guard, mutate]);

  return (
    <>
      <Button
        disabled={isLoading || !deal || disabled}
        variant={guardStatus === 'rejected' ? 'contained' : 'outlined'}
        color='error'
        onClick={() => setOpenReject(true)}
      >
        {guardStatus === 'rejected' ? 'Rejected' : 'Reject'}
      </Button>
      {openReject &&
        <DealGuardRejectDialog
          open={openReject}
          initReason={guard?.reason}
          onCancel={() => setOpenReject(false)}
          onConfirm={handleRejectDealGuard}
        />
      }
    </>
  );
};


export default RejectDealButton;