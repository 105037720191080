import React from "react";

import {
  Autocomplete,
  AutocompleteProps,
  TextField,
} from "@mui/material";
import { Dealer } from "../../../types/dealer";
import { useDealers } from "../../providers/DealersProvider";


export type DealerSelectProps = Omit<AutocompleteProps<Dealer, false, false, false>, 'renderInput' | 'options' | 'getOptionLabel'>;
export function DealerSelect({ onChange, ...props }: DealerSelectProps) {
  const { dealers } = useDealers();
  return (
    <Autocomplete
      options={dealers}
      getOptionLabel={(option: Dealer) => option.name}
      isOptionEqualToValue={(option: Dealer, value: Dealer) => option.id === value.id}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label="Select Dealer"/>}
      {...props}
    />
  );
}

export default React.memo(DealerSelect);