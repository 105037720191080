import { MouseEvent, useCallback, useState } from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Menu, MenuList, MenuItem, IconButton } from '@mui/material';
import UserAccess from '../dumps/UserAccess';
import Link from '../dumps/Link';

const AdminButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <UserAccess roles={['admin']}>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='primary'
      >
        <AdminPanelSettingsIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList dense>
          <Link to='/my-deals'>
            <MenuItem>
              My deals
            </MenuItem >
          </Link>
          <Link to='/manage-deals'>
            <MenuItem>
              Manage deals
            </MenuItem >
          </Link>
          <Link to='/import-deals'>
            <MenuItem>
              Import deals
            </MenuItem >
          </Link>
        </MenuList>
      </Menu>
    </UserAccess>
  );
};

export default AdminButton;

