import { Box, Container, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Header from '../containers/Header';

interface IProps {
  hideBack?: boolean,
  title?: string | null,
  children?: ReactNode,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
}

const Page = ({ title, children, maxWidth = 'xl' }: IProps) => {
  return (
    <>
      <Header />
      <Container maxWidth={maxWidth}>
        {title && <Typography variant='h3' mt={2}>{title}</Typography>}
        {children}
      </Container>
    </>
  );
};

export default Page;