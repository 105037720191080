import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../components/layouts/Page';
import {
  Box,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography
} from '@mui/material';
import { guardStatusToColor2 } from '../lib/guardStatusToColor';
import { Deal, DEAL_TYPES } from '../const/deal';
import { DealGuard } from '../const/deal-guard';
import useSWR from 'swr';
import SearchLine from '../components/dumps/SearchLine';
import { dateToString, filderDeal, guardStatusToTitle, guardToStatus } from '../lib/deal';
import DelearsSelect from '../components/containers/dealer/DelearsSelect';
import { DealersProvider, useDealers } from '../components/providers/DealersProvider';
import { Dealer } from '../types/dealer';
import { Link } from 'react-router-dom';

const tableHeaders = ['Dealer', 'Country', 'Customer', 'Email', 'Phone', 'Created at', 'Lead coming date', 'Deal type', 'Guard status'];

function useDeals({ search, limit, partnerIds }: { search: string, limit: number, partnerIds: string[] }) {
  const { data: deals = [], isLoading, mutate } = useSWR<Deal[]>('/api/v1/deals');
  const { dealerMap } = useDealers();


  const dealsWithGuards = useMemo(() =>
    deals.map((deal: Deal) => ({
      ...deal,
      dealer: dealerMap[deal.partnerId as string]
    })),
    [deals, dealerMap]
  );
  const filteredDeals = useMemo(() => {
    if (partnerIds.length === 0) {
      return dealsWithGuards.filter((deal: Deal) => filderDeal(deal, search));
    }
    return dealsWithGuards
      .filter((deal: Deal) => partnerIds.includes(deal.partnerId || ''))
      .filter((deal: Deal) => filderDeal(deal, search))
  }, [dealsWithGuards, search, partnerIds]);

  return {
    isLoading,
    deals: filteredDeals.slice(0, limit),
    amount: filteredDeals.length,
    mutate,
  };
}

type DealItemProps = TableRowProps & {
  deal: Deal;
  dealer?: Dealer;
  dealGuard?: DealGuard;
  onEdit?: (data: Deal) => void;
}
const DealItem: React.FC<DealItemProps> = ({ deal, dealer, dealGuard, onEdit, ...props }) => {
  return (
    <TableRow
      component={Link}
      to={`/deal-guard/${deal.id}`}
      sx={{
        textDecoration: 'none',
        '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'
      }}
      onClick={() => onEdit?.(deal)}
      hover
      {...props}
    >
      <TableCell>{dealer?.name || ''}</TableCell>
      <TableCell>{deal.country}</TableCell>
      <TableCell>{deal.customerName}</TableCell>
      <TableCell>{deal.email}</TableCell>
      <TableCell>{deal.phone}</TableCell>
      <TableCell>
        {dateToString(deal.createdAt)}
      </TableCell>
      <TableCell>
        {dateToString(deal.leadComingDate)}
      </TableCell>
      <TableCell>{DEAL_TYPES[deal.dealType]}</TableCell>
      <TableCell>
        <Typography variant='body2' sx={{ color: guardStatusToColor2(dealGuard?.status) }}>
          {guardToStatus(dealGuard)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
const MemoDealItem = React.memo(DealItem);


const ManageDeals: React.FC = () => {
  const [search, setSearch] = useState('');
  const [selectedDealers, setSelectedDealers] = useState<string[]>([]);
  const [limit, setLimit] = useState(20);

  const { deals, isLoading, amount } = useDeals({ search, limit, partnerIds: selectedDealers });

  const handleSelectDealer = useCallback((data: Dealer[] = []) => {
    const selectedDealers = data.map((d: Dealer) => d.id);
    setSelectedDealers(selectedDealers);
  }, [deals])
  
  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.body.offsetHeight - 300; // 300px

    if (scrollPosition >= threshold && limit < amount) {
      setLimit(prevLimit => Math.min(prevLimit + 20, amount));
    }
  }, [limit, amount]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = useCallback((filter: string) => {
    setSearch(filter);
    setLimit(20);
  }, [])

  return (
    <Page maxWidth={false}>
      <Stack direction="row" py={2} gap={2}>
        <Box sx={{ flexGrow: 1 }}>
          <SearchLine
            onChangeFilter={handleSearch}
            onSearch={handleSearch}
          />
        </Box>
        <DelearsSelect onSelected={handleSelectDealer} sx={{ minWidth: 300 }} />
      </Stack>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {deals.map((deal) => (
                <MemoDealItem
                  key={deal.id}
                  deal={deal}
                  dealer={deal.dealer}
                  dealGuard={deal.guard}
                />
              ))}
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={9} align="center" sx={{ border: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
};


const ManageDealsWithProviders: React.FC = () => {
  return (
    <DealersProvider>
      <ManageDeals />
    </DealersProvider>
  )
}

export default ManageDealsWithProviders;