import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import * as dealGuardsApi from '../../../api/deal-guards';
import DealGuardSuccessDialog from '../../../components/dialogs/DealGuardSuccessDialog';
import toast from 'react-hot-toast';
import { useDeal } from '../../../components/providers/DealProvider';

export type ApproveDealButtonProps = ButtonProps;

const ApproveDealButton: React.FC<ApproveDealButtonProps> = ({ disabled, ...props }) => {
  const { deal, isLoading, mutate } = useDeal();

  const [openSuccess, setOpenSuccess] = useState(false);

  const guard = deal?.guard;
  const guardStatus = guard?.status

  const handleApproveDealGuard = useCallback(async (protectionExpiryDate: string) => {
    try {
      if (guard) {
        await toast.promise(dealGuardsApi.update({ ...guard, status: 'approved', protectionExpiryDate }), {
          loading: 'Approving...',
          success: 'Guard approved successfully',
          error: 'Failed to approve guard'
        });
        mutate();
        setOpenSuccess(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [guard, mutate]);

  return (
    <>
      <Button
        disabled={isLoading || !deal || disabled}
        variant={guardStatus === 'approved' ? 'contained' : 'outlined'}
        color='success'
        onClick={() => setOpenSuccess(true)}
        {...props}
      >
        {guardStatus === 'approved' ? 'Approved' : 'Approve'}
      </Button>
      {openSuccess &&
        <DealGuardSuccessDialog
          open={openSuccess}
          initExpireDate={guard?.protectionExpiryDate || ''}
          onCancel={() => setOpenSuccess(false)}
          onConfirm={handleApproveDealGuard}
        />
      }
    </>
  );
};

export default ApproveDealButton;