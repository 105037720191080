import React, { createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';
import cfg from '../../config';
import { Dealer } from '../../types/dealer';

type DealersContextType = {
  dealers: Dealer[];
  dealerMap: { [key: string]: Dealer };
};

const DealersContext = createContext<DealersContextType | undefined>(undefined);

export type DealersProviderProps = {
  children: React.ReactNode;
};

export const DealersProvider: React.FC<DealersProviderProps> = ({ children }) => {
  const { data: dealers = [] } = useSWR<Dealer[]>(`${cfg.dealerMicro}/api/v1/dealers`);
  const dealerMap = useMemo(() => Object.fromEntries(dealers.map((d: Dealer) => [d.id, d])), [dealers]);

  return (
    <DealersContext.Provider value={{ dealers, dealerMap }}>
      {children}
    </DealersContext.Provider>
  );
};

export const useDealers = (): DealersContextType => {
  const context = useContext(DealersContext);
  if (!context) {
    throw new Error('useDealers must be used within a DealersProvider');
  }
  return context;
};
