import { useForm } from "react-hook-form"
import { Box, Button, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import { FormInputText } from "../form/FormInputText"
import { Deal, DealData } from "../../const/deal"
import { useEffect, useState } from "react"

const defaultInit: DealData = {
  customerName: '',
  country: '',
  email: '',
  phone: '',
  dealType: 'directSale',
}

type Props = {
  open: boolean
  onClose: () => void
  onSave: (data: Deal, tenderDate?: string) => void
}

function ReqestDealDialog({ open, onClose, onSave }: Props) {
  const { control, handleSubmit, reset, watch } = useForm<Deal>({ defaultValues: defaultInit, mode: 'onSubmit' })

  const [isTenderDate, setIsTenderDate] = useState(false)
  const [tenderDate, setTenderDate] = useState<string | undefined>(undefined)

  useEffect(() => {
    const { unsubscribe } = watch((v) => {
      setIsTenderDate(v.dealType === 'tender')
    })
    return () => unsubscribe()
  }, [watch])

  const handleSave = (data: Deal) => {
    onSave(data, tenderDate);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Request dela protection</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSave)}>
          <Stack spacing={2} py={2}>
            <FormInputText
              name="customerName"
              control={control}
              label="Customer name"
              required
              errorMessage='Please enter customer name'
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="country"
              control={control}
              label="Country"
              required
              errorMessage='Please enter country'
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="email"
              control={control}
              label="Email"
              type="email"
              required
              errorMessage='Please enter email'
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              errorMessage='Please enter phone'
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="dealType"
              select
              control={control}
              label="Deal Type"
              errorMessage={'Please enter deal type'}
            >
              <MenuItem value="directSale">Direct Sale</MenuItem>
              <MenuItem value="tender">Tender</MenuItem>
            </FormInputText>
            {isTenderDate && (
              <TextField
                name="tenderDate"
                type="date"
                label="Tender Date"
                value={tenderDate}
                InputLabelProps={{ shrink: true }}
                size="small"
                onChange={(e) => {setTenderDate(e.target.value)}}
              />
            )}
            <FormInputText
              name="leadComingDate"
              type="date"
              control={control}
              label="Lead Coming Date"
              inputLabelProps={{ shrink: true }}
            />
            <FormInputText
              name="comments"
              multiline
              control={control}
              label="Comments"
              inputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
            <Button variant="contained" color="secondary" onClick={onClose} sx={{ mr: 2 }}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Save</Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ReqestDealDialog;
