import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Tab, Tabs, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";

type Props = DialogProps & {
  open: boolean;
  initExpireDate?: string; // Сделал дату необязательной
  onCancel: () => void;
  onConfirm: (expireDate: string) => void;
};

function DealGuardSuccessDialog({ open, initExpireDate, onCancel, onConfirm, ...rest }: Props) {
  const defaultPeriod = "3m";
  const [selectedTab, setSelectedTab] = useState(initExpireDate ? 1 : 0);
  const [expireDate, setExpireDate] = useState<string | null>(
    initExpireDate ? initExpireDate.split("T")[0] : null
  );
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);

  const handleConfirm = useCallback(() => {
    if (selectedTab === 0) {
      const periodToAdd = {
        "1m": { months: 1 },
        "2m": { months: 2 },
        "3m": { months: 3 },
        "6m": { months: 6 },
        "1y": { years: 1 },
      }[selectedPeriod];

      if (periodToAdd) {
        const calculatedDate = DateTime.now().plus(periodToAdd).toISODate();
        onConfirm(calculatedDate);
      }
    } else if (selectedTab === 1 && expireDate) {
      onConfirm(expireDate);
    }
  }, [onConfirm, selectedTab, selectedPeriod, expireDate]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return open && (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle>Success deal guard</DialogTitle>
      <DialogContent>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Select Period" />
          <Tab label="Select Date" />
        </Tabs>

        {selectedTab === 0 && (
          <Box mt={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
              {["1m", "2m", "3m", "6m", "1y"].map((period) => (
                <Button
                  key={period}
                  variant={selectedPeriod === period ? "contained" : "outlined"}
                  onClick={() => setSelectedPeriod(period)}
                  sx={{ flexGrow: 1 }}
                >
                  {period.toUpperCase()}
                </Button>
              ))}
            </Box>
          </Box>
        )}

        {selectedTab === 1 && (
          <TextField
            label="Expire date"
            type="date"
            value={expireDate ?? ""}
            onChange={(e) => setExpireDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ my: 2 }}
          />
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={2}>
          <Button variant="contained" color="secondary" sx={{ mr: 2 }} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={selectedTab === 1 && !expireDate}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DealGuardSuccessDialog;
