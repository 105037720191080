import React from "react"
import { MenuItem } from "@mui/material"
import { FormInputProps, FormInputText } from "../../form/FormInputText"
import { useDealers } from "../../providers/DealersProvider"

export type DealerFormInputProps = FormInputProps;
function DealerFormInput(props: DealerFormInputProps) {
  const { dealers } = useDealers();

  return (
    <FormInputText
      select
      label="Partner"
      errorMessage={'Please select partner'}
      {...props}
    >
      {dealers.map((d) => (
        <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
      ))}
    </FormInputText>
  )
}

export default React.memo(DealerFormInput);