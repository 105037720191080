import React, { FC } from 'react';
import { Controller, Validate } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type FormInputProps = {
  control: any;
  name: string;
  register?: any;
  errorMessage?: string | null;
  rules?: {
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    validate?: Validate<any, any>;
  };
  inputLabelProps?: {
    shrink: boolean;
  };
  isNumber?: boolean,
  thousandSeparator?: string,
  onBlur?: (data: any) => void,
} & Omit<TextFieldProps, 'variant' | 'size' | 'error' | 'name' | 'onBlur'>;

const integerReg = /[^0-9]/g;

export const FormInputText: FC<FormInputProps> = (props) => {
  const {
    name,
    type,
    control,
    register,
    label,
    required,
    multiline,
    disabled,
    rows,
    fullWidth = true,
    inputProps,
    inputLabelProps,
    errorMessage,
    rules,
    isNumber,
    thousandSeparator,
    onBlur,
    ...restProps
  } = props;

  const allowOnlyNumber = (value: string) => {
    if (thousandSeparator) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    }

    return value.replace(integerReg, '');
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required || false,
          message: errorMessage || 'Field is required',
        },
        min: rules?.min,
        max: rules?.max && {
          value: rules.max,
          message: 'Maximum 100',
        },
        minLength: rules?.minLength && {
          value: rules.minLength,
          message: `Minimum number of characters ${rules.minLength}`,
        },
        maxLength: rules?.maxLength && {
          value: rules.maxLength,
          message: `Maximum number of characters ${rules.maxLength}`,
        },
        validate: rules?.validate,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          type={type ? type : 'text'}
          size='small'
          error={!!error}
          onChange={(e) => onChange(isNumber ? allowOnlyNumber(e.target.value) : e.target.value)}
          value={value || ''}
          required={required}
          fullWidth={fullWidth}
          label={label}
          variant='outlined'
          multiline={multiline}
          rows={rows}
          inputProps={inputProps}
          disabled={disabled}
          InputLabelProps={inputLabelProps}
          onBlur={onBlur}
          {...register}
          {...restProps}
        />
      )}
    />
  );
};
