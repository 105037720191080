import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  BoxProps,
  TableRowProps
} from "@mui/material";
import { SimilarDeal, DEAL_TYPES } from "../../../const/deal";
import { DealGuard } from "../../../const/deal-guard";
import { guardStatusToColor2 } from "../../../lib/guardStatusToColor";
import useSWR from "swr";
import { useMemo } from "react";
import { useDealers } from "../../providers/DealersProvider";
import { Dealer } from "../../../types/dealer";
import { useDeal } from "../../providers/DealProvider";
import { dateTimeToString, dateToString, guardStatusToTitle } from "../../../lib/deal";
import Link from "../../dumps/Link";

const tableHeaders = ['Dealer', 'Country', 'Customer', 'Email', 'Phone', 'Created at', 'Lead coming date', 'Deal type', 'Guard status', 'Similar'];

function useDeals(dealId: string) {
  const { data: similarDeals = [] } = useSWR<SimilarDeal[]>(`/api/v1/deals/${dealId}/similar`);
  const { dealerMap } = useDealers();

  const similarDealsWithoutCurrent = useMemo(() => {
    return similarDeals.filter((sd: SimilarDeal) => sd.id !== dealId) || [];
  }, [dealId, similarDeals]);

  const merged = useMemo(() => {
    return similarDealsWithoutCurrent.map((sd: SimilarDeal) => ({
      ...sd,
      dealer: dealerMap[sd.partnerId as string]
    }));
  }, [similarDealsWithoutCurrent, dealerMap]);

  const sorted = useMemo(() => {
    // first high, then medium, then low
    return merged.sort((a, b) => {
      if (a.matchRating === 'high') return -1;
      if (b.matchRating === 'high') return 1;
      if (a.matchRating === 'medium') return -1;
      if (b.matchRating === 'medium') return 1;
      return 0;
    });
  }, [merged]);
  
  return {
    deals: sorted,
  };
}

function matchRating2Color(matchRating: SimilarDeal['matchRating']) {
  switch (matchRating) {
    case 'high':
      return 'error.main';
    case 'medium':
      return 'warning.main';
    case 'low':
      return 'grey';
  }
}

function matchRating2Text(matchRating: SimilarDeal['matchRating']) {
  switch (matchRating) {
    case 'high':
      return 'High';
    case 'medium':
      return 'Medium';
    case 'low':
      return 'Low';
  }
}

type DealItemProps = TableRowProps & {
  deal: SimilarDeal;
  dealer?: Dealer;
  dealGuard?: DealGuard;
};

const DealItem: React.FC<DealItemProps> = ({ deal, dealer, dealGuard, ...props }) => {
  return (
    <TableRow
      component={Link}
      to={`/deal-guard/${deal.id}`}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: deal.matchRating === 'high' ? 'rgba(255, 0, 0, 0.1)' : 'inherit'
      }}
      hover
      {...props}
    >
      <TableCell>{dealer?.name}</TableCell>
      <TableCell>{deal.country}</TableCell>
      <TableCell>{deal.customerName}</TableCell>
      <TableCell>{deal.email}</TableCell>
      <TableCell>{deal.phone}</TableCell>
      <TableCell>{dateTimeToString(deal.createdAt)}</TableCell>
      <TableCell>{dateToString(deal.leadComingDate)}</TableCell>
      <TableCell>{DEAL_TYPES[deal.dealType]}</TableCell>
      <TableCell>
        <Typography
          variant='body2' sx={{ color: guardStatusToColor2(dealGuard?.status) }}
        >
          {guardStatusToTitle(dealGuard?.status)}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant='body2' sx={{ color: matchRating2Color(deal.matchRating) }}>
          {matchRating2Text(deal.matchRating)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
type SimilarDealsProps = BoxProps;

function SimilarDeals({ ...props }: SimilarDealsProps) {
  const { dealId } = useDeal();
  const { deals } = useDeals(dealId);

  return (
    <Box {...props}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!deals.length && 
              <TableRow><TableCell colSpan={tableHeaders.length} align='center'>No similar deals</TableCell></TableRow>
            }
            {deals.map((sd) => (
              <DealItem
                key={sd.id}
                deal={sd}
                dealer={sd.dealer}
                dealGuard={sd.guard}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SimilarDeals;