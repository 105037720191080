import React, { useCallback } from 'react';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import useSWR from 'swr';
import cfg from '../../../config';
import { useDealers } from '../../providers/DealersProvider';

export type Dealer = {
  id: string;
  name: string;
}

type BaseProps = AutocompleteProps<Dealer, true, false, false>;
export type DelearsSelectProps = Omit<BaseProps, 'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'onChange' | 'renderInput'> & {
  onSelected: (data: Dealer[]) => void
}

function DelearsSelect({ onSelected, ...props }: DelearsSelectProps) {
  const { dealers } = useDealers();

  const handleChange = useCallback((_: any, data: Dealer[] = []) => {
    onSelected(data);
  }, [dealers, onSelected])

  return (
    <Autocomplete
      id="dealer-filter"
      multiple
      options={dealers}
      getOptionLabel={(option: Dealer) => option.name}
      isOptionEqualToValue={(option: Dealer, value: Dealer) => option.id === value.id}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label="Dealer filter" size='small'/>}
      {...props}
    />
  )
}

export default React.memo(DelearsSelect);