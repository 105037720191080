import React, { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import MuiPaper from '@mui/material/Paper';
import MuiInputBase from '@mui/material/InputBase';
import MuiIconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { throttle } from 'lodash'

const Paper = styled(MuiPaper)({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})

const InputBase = styled(MuiInputBase)(({ theme }) =>({
  marginLeft: theme.spacing(1),
  flex: 1,
}))

const IconButton = styled(MuiIconButton)(({ theme }) =>({
  padding: theme.spacing(1),
}))

type Props = {
  value?: string,
  autoFocus?: boolean,
  dense?: boolean,
  placeholder?: string,
  onChangeFilter?: (filter: string) => void,
  onSearch: (filter: string) => void,
}

const SearchLine = ({ value, dense, autoFocus, placeholder, onChangeFilter, onSearch }: Props) => {
  const [ _filter, setFilter ] = useState(value || '')
  useEffect(() => {
    setFilter(value || '')
  }, [value])

  const handleChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
    onChangeFilter && throttle(onChangeFilter, 500)(event.target.value)
  }, [setFilter, onChangeFilter])

  const handleSearch = useCallback(() => {
    onSearch(_filter)
  }, [_filter, onSearch])

  return (
    <Paper>
      {
        !dense && (
          <IconButton aria-label="menu">
            <MenuIcon />
          </IconButton>
        )
      }
      <InputBase
        autoFocus={autoFocus}
        placeholder={placeholder || "Search"}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChangeFilter}
      />
      <IconButton type="submit" color="primary" aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default React.memo(SearchLine)