import { ButtonProps, IconButton, IconButtonProps } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import EditDealDialog from "../dialogs/EditDealDialog";
import { Deal } from "../../const/deal";
import * as dealApi from '../../api/deal';
import toast from "react-hot-toast";
import EditRequestDialog from "./EditRequestDialog";

type Props = Omit<IconButtonProps, 'onClick' | 'children' | 'onSubmit'> & {
  init: Deal
  initTenderDate?: string
  disabled?: boolean
  onUpdated?: (data: Deal) => void
}

function EditReqestButton({ init, initTenderDate, disabled, onUpdated, ...props }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true)
  }, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(async ({ id, ...data }: Deal, tenderDate?: string) => {
    try {
      const newDeal = await toast.promise(dealApi.updateRequest(id, data), {
        loading: 'Saving...',
        success: 'Deal saved',
        error: 'Error saving deal'
      });
      setOpen(false);
      onUpdated?.(newDeal);
    } catch (e) { 
      console.error(e);
    }
  }, [onUpdated]);

  return (
    <>
      <IconButton aria-label="edit" disabled={disabled} onClick={handleOpen} {...props}>
        <EditIcon />
      </IconButton>
      {open && 
        <EditRequestDialog
          open={open}
          init={init}
          initTenderDate={initTenderDate}
          onClose={handleClose}
          onSave={handleSubmit}
        />
      }
    </>
  );
}

export default EditReqestButton;
