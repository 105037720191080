import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, TextField } from "@mui/material";
import { useCallback, useState } from "react";

type Props = DialogProps & {
  open: boolean;
  initReason?: string;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
};

function DealGuardRejectDialog({ open, initReason, onCancel, onConfirm, ...rest }: Props) {
  const [reason, setReason] = useState(initReason || "");

  const handleConfirm = useCallback(() => {
    onConfirm(reason);
  }, [onConfirm, reason]);

  return open && (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle>Reject deal guard</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Reason"
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={2}>
          <Button variant="contained" color="secondary" sx={{ mr: 2 }} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DealGuardRejectDialog;
