import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';
import Logo from '../dumps/Logo';
import ProfileButton from './ProfileButton';
import AdminButton from './AdminButton';

const Header = () => {

  return (
    <AppBar
      position='sticky'
      sx={{
        background: 'transparent',
        boxShadow: 'none',
        backdropFilter: 'blur(2px)',
        borderBottom: '1px solid #393642',
      }}>
      <Container maxWidth='xl'>
        <Toolbar
          variant='dense'
          sx={{
            gap: 1,
            minHeight: '70px',
            justifyContent: 'space-between',
          }}
        >
          <Link href='/' underline='none'>
            <Logo mr={3} sx={{ cursor: 'pointer' }} />
          </Link>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <AdminButton />
            <ProfileButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
