import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useDeal } from '../../providers/DealProvider';

export type CustomerNameProps = Omit<TypographyProps, 'children'>;

const CustomerName: React.FC<CustomerNameProps> = (props) => {
  const { deal } = useDeal();
  return (
    <Typography variant='h6' {...props}>
      {deal?.customerName || '...'}
    </Typography>
  )
}

export default CustomerName;