import React, { useEffect } from 'react';
import Page from '../components/layouts/Page';
import { Typography } from '@mui/material';
import { useAuth } from '../components/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../const/roles';

const Home: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.roles.includes(ROLES.MANAGER)) {
      navigate('/manage-deals');
    } else if (user?.roles.includes(ROLES.DEALER)) {
      navigate('/my-deals');
    }
  }, [user]);

  return (
    <Page>
      <Typography variant='h2' align='center' mt={30} color={'#838383'}>UTS Deals Guard service</Typography>
      <Typography variant='h4' align='center' mt={3} color={'#838383'}>Please contact your UTS company manager to gain access.</Typography>
    </Page>
  );
};

export default Home;