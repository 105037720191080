import { DateTime } from "luxon"
import { Deal } from "../const/deal"
import { DealGuard, DealGuardStatus } from "../const/deal-guard"

export const filderDeal = (deal: Deal, search: string) => {
  return deal.customerName?.toLowerCase().includes(search.toLowerCase()) ||
    deal.email?.toLowerCase().includes(search.toLowerCase()) ||
    deal.phone?.toLowerCase().includes(search.toLowerCase()) ||
    deal.country?.toLowerCase().includes(search.toLowerCase()) ||
    deal.dealType?.toLowerCase().includes(search.toLowerCase()) ||
    deal.comments?.toLowerCase().includes(search.toLowerCase())
}

export const guardStatusToTitle = (status?: DealGuardStatus) => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'approved':
      return 'Approved'
    case 'rejected':
      return 'Rejected'
    default:
      return 'Unknown'
  }
}

export function guardToStatus(guard?: DealGuard) {
  switch (guard?.status) {
    case 'pending':
      return 'Pending'
    case 'approved':
      return `Approved until ${dateToString(guard.protectionExpiryDate)}`
    case 'rejected':
      return 'Rejected'
    default:
      return 'Unknown'
  }
}

export const dateToString = (date?: Date | string) => {
  return date ? DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_MED) : '-'
}

export const dateTimeToString = (date?: Date | string) => {
  return date ? DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATETIME_MED) : '-'
}