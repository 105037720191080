export type DealGuardStatus = 'pending' | 'approved' | 'rejected' | 'expired';
export type GuardStatusColor = 'primary' | 'success' | 'warning' | 'error';
export type GuardStatusColor2 = 'primary.main' | 'success.main' | 'warning.main' | 'error.main';

export function guardStatusToColor(status?: DealGuardStatus): GuardStatusColor {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'approved':
      return 'success';
    case 'rejected':
      return 'error';
    default:
      return 'primary';
  }
}

export function guardStatusToColor2(status?: DealGuardStatus): GuardStatusColor2 {
  switch (status) {
    case 'pending':
      return 'warning.main';
    case 'approved':
      return 'success.main';
    case 'rejected':
      return 'error.main';
    default:
      return 'primary.main';
  }
}