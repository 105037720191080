import { DealGuard } from "./deal-guard";

export type DealType = 'directSale' | 'tender';

export enum DEAL_TYPES {
  directSale = 'Direct Sale',
  tender = 'Tender',
}


export type DealData = {
  partnerId?: string;
  customerName: string;
  country: string;
  email: string;
  phone: string;
  dealType: DealType;
  leadComingDate?: Date | string;
  comments?: string;
  createdAt?: Date | string; //hidden
  updatedAt?: Date | string; //hidden
}

export type Deal = DealData & {
  id: string;
  guard?: DealGuard;
}

export type SimilarDeal = Deal & {
  matchRating: 'high' | 'medium' | 'low';
}
